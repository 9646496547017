import {
	initSuppressConversionPixel,
	blockDuplicateSubmissionSameHeapSessionId,
	switchFormLoadingState,
	switchFormSubmitState,
	validateRecaptchaResponse,
	processFormSubmitError,
	submitFormDataToQSApi,
	submitFormDataToFormsServiceApi,
	appendRecaptchaToken,
	subscribeToNonTYPMutations,
	submitContractorFormDataToApi,
	addUserConsentTcpaToHeap,
	initFormSubmitLoader,
	trackFormSubmissionFlow
} from '../../../helpers';

/**
 * Submit form action
 * @param {Object} formData - form data object
 */
export function submitForm(formData) {
	initFormSubmitLoader();

	trackFormSubmissionFlow({ inSubmitForm: true });

	// set up observers as early as possible
	const observer = new MutationObserver(subscribeToNonTYPMutations.bind(window.modForm));
	observer.observe(window.modForm.mutationObserverTarget, window.modForm.mutationObserverConfig);
	// Fire callback before submitting
	if (window.modForm.opts.formSubmittingCallback && 'function' === typeof window.modForm.opts.formSubmittingCallback) {
		formData = window.modForm.opts.formSubmittingCallback(formData);
		trackFormSubmissionFlow({ formSubmittingCallbackCalled: true });
	}

	if (window.modForm.opts.fetchAndFormatConsentListings && !window.modForm.opts.ignoreSendingUserTcpaToHeap) {
		addUserConsentTcpaToHeap();
	}

	// Check that formData exists
	if (!formData) {
		trackFormSubmissionFlow({ noFormData: true });
		return;
	}

	initSuppressConversionPixel(formData);

	if (!window.modForm.opts.ignoreDuplicateSubmissionBlocker) {
		blockDuplicateSubmissionSameHeapSessionId(formData);
	}

	if (!window.modForm.preventDuplicateSubmission) {
		// Switch form to the loading & submitting state
		switchFormLoadingState(true);

		if (window.modForm.opts.showLoadingOverlay) {
			trackFormSubmissionFlow({ showLoadingOverlay: true });
			const $loader = document.getElementsByClassName('loader')[0];

			if ($loader) {
				$loader.classList.add('loader--visible');
				$loader.setAttribute('aria-hidden', 'false');
				$loader.focus();
				window.modUtils.heap('track', ['Loader Overlay Initiated']);
			} else {
				window.modUtils.heap('track', ['Loader Not Found']);
			}
			switchFormSubmitState('submitting');
		}
	}

	// Check for recaptcha token
	if (window.isWordpress || window.modForm.opts.validateRecaptcha) {
		const isHomeowner = window.modForm.opts.vertical !== 'contractor';
		const useFullDomain = !document.location.href.includes('modernize');
		// Validate recaptcha for leads from WP Homeowner Pages or S3 without referrer
		if (isHomeowner && !window.modUtils.getCookie('original_referrer') && !window.modForm.opts.isOrganicPreferredMatch) {
			validateRecaptchaResponse(formData.recaptcha, function(isRecaptchaValid, errorMessage = '') {
				// Check that formData exists
				if (!isRecaptchaValid) {
					window.modUtils.heap('track', [
						'Token Error', {
							token: 'reCaptcha',
							errorMessage: errorMessage
						}
					]);
					processFormSubmitError();
					return;
				}

				window.modUtils.heap('track', [
					'Token Success',
					{
						token: 'reCaptcha'
					}
				]);

				// Submit data to API
				if (window.modForm.opts.useContractorApi) {
					submitContractorFormDataToApi(formData);
				} else if (window.modForm.opts.useFormsServiceApi && !window.modForm.opts.isCrossSellForm) {
					submitFormDataToFormsServiceApi(formData);
				} else {
					submitFormDataToQSApi(formData);
				}
			}, useFullDomain);
			return;
		}
	} else if (window.__recaptchaKey && window.modForm.opts.recaptchaVersion === 3) {
		const appendRecaptchaTokenCallBack = function(newFormData) {
			trackFormSubmissionFlow({ inAppendRecaptchaTokenCallBack: true });
			// Check that formData exists
			if (!newFormData) {
				return;
			}

			// Submit data to API
			if (window.modForm.opts.useContractorApi) {
				submitContractorFormDataToApi(newFormData);
			} else if (window.modForm.opts.useFormsServiceApi && !window.modForm.opts.isCrossSellForm) {
				submitFormDataToFormsServiceApi(newFormData);
			} else {
				submitFormDataToQSApi(newFormData);
			}

			return;
		};

		if ('grecaptcha' in window) {
			appendRecaptchaToken(formData, appendRecaptchaTokenCallBack);
		} else {
			const recaptchaObserver = new MutationObserver(function(mutations, observer) { // eslint-disable-line no-shadow
				trackFormSubmissionFlow({ inRecaptchaObserver: true });
				if (document.contains(document.getElementsByClassName('grecaptcha-badge')[0])) {
					appendRecaptchaToken(formData, appendRecaptchaTokenCallBack);
					observer.disconnect();
				}
			});
			recaptchaObserver.observe(document.body, { childList: true, subtree: true, attributes: true });
		}
		return;
	}

	if (!window.modForm.preventDuplicateSubmission) {
		// Submit data to API
		if (window.modForm.opts.useContractorApi) {
			submitContractorFormDataToApi(formData);
		} else if (window.modForm.opts.useFormsServiceApi && !window.modForm.opts.isCrossSellForm) {
			submitFormDataToFormsServiceApi(formData);
		} else {
			submitFormDataToQSApi(formData);
		}
	}
}
