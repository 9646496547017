import { trackFormSubmissionFlow } from '../../../helpers';
/* This function adds a fallback value to OwnHome in case the field is missing from the form attribution. Thus to prevent errors with QS HS Endpoint.
 * @param {Object} formData - form data
 */
export function addFallbackOwnHome(formData) {
	trackFormSubmissionFlow({ inAddFallbackOwnHome: true });
	const OwnHomeValidValues = ['Yes', 'No'];

	if (formData.OwnHome === 'no') {
		formData.OwnHome = 'No';
	}

	if (OwnHomeValidValues.indexOf(formData.OwnHome) === -1) {
		formData.OwnHome = 'Yes';
		window.modUtils.heap('track', ['Homeowner Lead | Default Attribute', { attribute: 'OwnHome' }]);
	}

	return formData;
}
