import { addInvocaFallbackToDataLayer, addRequiredQSFieldsToForm, initQuadTransform, initQuadTransformRetry } from '../../../helpers';
/**
 * Init Quinstreet form opts
 */
export function initQSForm() {
	const myResultsPage = window.modUtils.getModernizeDomain() + '/my/results/';

	window.modForm.opts.tagIDTest = '203838617'; // Use this if there is no quadlink params
	window.modForm.opts.tagIDLive = '204673056'; // Use this if there is no quadlink params
	window.modForm.opts.leadSubmitApi = 'hs/tyPage/matches.jsp';
	window.modForm.opts.trafficDetailsApi = 'api/TrafficDetails/get';
	window.modForm.opts.isWirelesssApi = 'api/isWireless';
	window.modForm.opts.websiteName = window.location.host;
	window.modForm.opts.resultsPage = window.modForm.opts.resultsPage ? window.modForm.opts.resultsPage : myResultsPage;
	window.modForm.opts.errorRedirectPath = window.modForm.opts.errorRedirectPath ? window.modForm.opts.errorRedirectPath : myResultsPage;
	window.modForm.opts.landingPage = window.location.hostname + window.location.pathname;
	window.modForm.opts.quadLinkParams = {};
	window.modForm.opts.qsParams = window.modForm.opts.qsParams ? window.modForm.opts.qsParams : ['CCID', 'CLK', 'QTR'];
	window.modForm.opts.quadLinkHasBeenTransformed = false;
	window.modForm.opts.quadLinkData = {
		WebSiteName: window.modForm.opts.websiteName,
		quadlink: '',
		defaultQuad: '',
		tagID: ''
	};

	// We check if there's a quad link on the query string
	// If not we get the default link
	if (!window.modForm.opts.delayQuadTransformation) {
		initQuadTransform(true);
	}

	initQuadTransformRetry();

	addRequiredQSFieldsToForm();

	if (window.modUtils.getLandingPage().includes('bestcompany')) {
		addInvocaFallbackToDataLayer();
	}
}
