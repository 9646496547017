import { switchFormLoadingState, trackFormSubmissionFlow } from '../../helpers';
/**
 * Process Form Submit Error
 * @param {Object} xhr - XHR object
 * @param {String} status - status
 * @param {String} error - error
 */
export function processFormSubmitError(xhr, status, error) {
	trackFormSubmissionFlow({ inProcessFormSubmitError: true });
	if ('function' === typeof window.modForm.opts.formSubmitErrorCallback) {
		window.modForm.opts.formSubmitErrorCallback(xhr, status, error);
	}

	switchFormLoadingState(false);
	alert('Something went wrong, please try again.');
}
