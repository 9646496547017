export function heapIdentify() {
	if (window.heap) {
		// Heap Identify only if we have a click key value available
		if (typeof window.modForm.opts.quadLinkParams.CLK !== 'undefined') {
			if (window.modUtils.getEnv() === 'production') {
				if ('heap' in window && window.heap.identity === null) {
					window.Modalytics.heap('identify', [window.modForm.opts.quadLinkParams.CLK]);
				} else if (!window.heapListenerInitialized) {
					window.addEventListener('heapCookieResolved', function() {
						window.Modalytics.heap('identify', [window.modForm.opts.quadLinkParams.CLK]);
					}, { once: true });

					window.heapListenerInitialized = true;
				}
			} else {
				window.Modalytics.heap('identify', [window.modForm.opts.quadLinkParams.CLK]);
			}
		}
	} else {
		document.addEventListener('heap.loaded', function() {
			heapIdentify();
		});
	}
}
