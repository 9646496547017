import { trackFormSubmissionFlow } from '../../helpers';
/**
 * In order to determine if all gtm tags have fired, we're adding classes to the body when they load in
 * When both classes exist, redirect logic will then be followed
 * @param {Object} mutations - passed by mutation observer
 */
export function subscribeToNonTYPMutations(mutations) {
	trackFormSubmissionFlow({ inSubscribeToNonTYPMutations: true });
	mutations.some((record) => {
		if (record.type === 'attributes' && ~record.target.className.indexOf('gtm-aio-complete')) {
			window.modUtils.heap('track', ['Mutation Observer redirect']);
			// guarantee fallback setTimeout doesn't fire
			clearTimeout(window.timeOutId);

			if (!window.modForm.opts.autoRedirectToTyOrSorryPage) {
				return true;
			}

			if (window.Loader && window.modForm.opts.showRedirectOverlay) {
				window.Loader.showRedirectAnimation(); // eslint-disable-line no-undef
				window.modUtils.heap('track', ['Loader Visible - Transferring to Modernize']);
				setTimeout(function() {
					setTimeout(function() {
						window.location.href = window.modForm.projexURL;
					}, 500);
					document.body.classList.add('submitted');
				}, 2500);
			} else {
				document.body.classList.add('submitted');
				setTimeout(function() {
					window.location.href = window.modForm.projexURL;
				}, 500);
			}

			return true;
		}
	});
}
