import { mapServiceSpecificAttributes, getHSGeoLookupDetails, trackFormSubmissionFlow } from '../../helpers';

/**
 * Updating the formData object to match required format for new HS Forms Service API
 * @param {Object} formData - Object of form data
 * @returns {Object} Processed formData
 */
export function updateFormDataFormatForFormsService(formData) {
	if (!formData) {
		trackFormSubmissionFlow({ noFormDataExitingUpdateFormDataFormatForFormsService: true });
		return;
	}

	trackFormSubmissionFlow({ inUpdateFormDataFormatForFormsService: true });

	const { CCID, CLK, CROTag, EM, ExternalGlobalLeadID, FN, HeapEventID, HEAPID, HeapSessionID, HeapUserID, HomePhoneConsentLanguage, HP, InternalSource, LN, Match, PartnerSourceID, PC, PublisherSubID, QTR, quadlink, ReferredLead, S1, searchid, service, shClickKey, site, skipAddress, tagID, VWOID, VWOVariationName, TrustedFormToken } = formData;
	let { OwnHome } = formData;

	if (Object.keys(window.modForm.opts.serviceSpecificAttributes).length === 0) {
		mapServiceSpecificAttributes(service);
	}

	// OwnHome will be passed as a separate attribute (& not in the service specific)
	OwnHome = window.modForm.opts.serviceSpecificAttributes.OwnHome ? window.modForm.opts.serviceSpecificAttributes.OwnHome : OwnHome;
	delete window.modForm.opts.serviceSpecificAttributes.OwnHome;

	const additionalRootLevelAttributes = {
		...(TrustedFormToken && { 'trustedFormToken': TrustedFormToken }),
		...(PartnerSourceID && { 'partnerSourceID': PartnerSourceID }),
		...(PublisherSubID && { 'publisherSubID': PublisherSubID })
	};

	const consentCaptureData = {
		'consentMethod': 'Web',
		...(window.modForm.opts.consentRequestId && { 'requestId': window.modForm.opts.consentRequestId }),
		'vendors': window.modForm.opts.consentCaptureVendors,
		...(window.modForm.opts.consentCaptureLabels && { 'labels': window.modForm.opts.consentCaptureLabels })
	};

	const matchingAttributes = {
		...(Match && { 'brandedVendor': Match })
	};

	const serviceSpecificAttributes = {
		...window.modForm.opts.serviceSpecificAttributes
	};

	const configOverrideAttributes = {
		...(skipAddress === 'y' && { 'skipAddress': true })
	};

	const sureHitsAttributes = {
		...(searchid && { 'searchid': searchid }),
		...(shClickKey && { 'shclickkey': shClickKey })
	};

	const trackingAttributes = {
		...(CCID && { 'ccid': CCID }),
		...(CLK && { 'clk': CLK }),
		...(InternalSource && { 'internalSource': InternalSource }),
		...(QTR && { 'qtr': QTR }),
		...(quadlink && { 'quadLink': quadlink }),
		...(tagID && { 'quadTagId': tagID }),
		'site': site,
		'additionalAttributes': {
			...(HEAPID && { 'HEAPID': HEAPID }),
			...(HeapUserID && { 'HeapUserID': HeapUserID }),
			...(HeapEventID && { 'HeapEventID': HeapEventID }),
			...(HeapSessionID && { 'HeapSessionID': HeapSessionID }),
			...(VWOID && { 'VWOID': VWOID }),
			...(VWOVariationName && { 'VWOVariationName': VWOVariationName }),
			...(CROTag && { 'CROTag': CROTag }),
			...(ExternalGlobalLeadID && { 'ExternalGlobalLeadID': ExternalGlobalLeadID }),
			...(ReferredLead && { 'ReferredLead': ReferredLead })
		}
	};

	let landingPageURL = window.location.href.split('#')[0];
	// This is a part of LP-3270, this will be removed once the analysis is done.
	const HSGeolookupData = JSON.stringify(window.currentLocationFromHSGeolookup || {});

	if (landingPageURL.includes('?')) {
		if (landingPageURL.charAt(landingPageURL.length - 1) !== '&') {
			landingPageURL += '&';
		}
		landingPageURL += 'HSGeolookupData=' + HSGeolookupData;
	} else {
		landingPageURL += '?HSGeolookupData=' + HSGeolookupData;
	}
	// LP-3270 -- end

	const HSGeoLookupDetails = JSON.stringify(getHSGeoLookupDetails());
	const querySymbol = landingPageURL.includes('?') ? '&' : '?';
	landingPageURL += `${querySymbol}HSGeoLookupDetails=${HSGeoLookupDetails}`;

	const formattedData = {
		'zip': PC,
		'service': service,
		'firstName': FN,
		'lastName': LN,
		'email': EM,
		'phone': HP,
		'address': S1,
		'ownHome': OwnHome,
		'homePhoneConsentLanguage': HomePhoneConsentLanguage,
		'landingPageURL': landingPageURL,
		...additionalRootLevelAttributes,
		'consentCaptureData': {
			...consentCaptureData
		},
		...(Object.keys(matchingAttributes).length ? { matchingAttributes } : {}),
		...(Object.keys(serviceSpecificAttributes).length ? { serviceSpecificAttributes } : {}),
		...(Object.keys(configOverrideAttributes).length ? { configOverrideAttributes } : {}),
		...(Object.keys(sureHitsAttributes).length ? { sureHitsAttributes } : {}),
		...(Object.keys(trackingAttributes).length ? { trackingAttributes } : {})
	};

	return formattedData;
}
