
/**
 * Function to add heap event properties
 *
 * @param {[]} [properties=[]] - Array of properties
 * @returns {void}
 */
export const addToHeapEventAndUserProperties = function(properties = []) {
	const _fn = function(props) {
		if (props && Array.isArray(props) && props.length) {
			props.forEach(function(prop) {
				if (prop) {
					window.Modalytics.heap('addEventProperties', prop);
					window.Modalytics.heap('addUserProperties', prop);
				}
			});
		}
	};

	if (window.heap) {
		_fn(properties);
	} else {
		document.addEventListener('heap.loaded', function() {
			_fn(properties);
		});
	}
};
