import { switchFormLoadingState, processAPIResponseErrors, showNextStep } from '../../helpers';

/**
 * Process form after succesful submit
 * @param {Object} response - response data
 * @param {Number} currentStep - current step index
 * @param {Object} xhr - xhr
 */
export function processContractorFormSubmitSuccess(response, currentStep, xhr) {
	let heapTrackData;

	// Handle errors
	// This needs to get worked on for better identification, since the PATCH call doesn't return any response,
	// we can't assume "having no response" is the way of saying there was an error
	// Would like to know how can I replicate this behaviour first for a more thorough testing
	if (xhr.statusText === 'error') {
		heapTrackData = {
			message: 'Failed Contractor Signup Form Submit',
			data: window.modForm.opts.landingPage
		};

		if ('function' === typeof window.modForm.opts.formSubmitErrorCallback) {
			window.modForm.opts.formSubmitErrorCallback(response);
		}

		switchFormLoadingState(false);
		processAPIResponseErrors(response);

		return;
	}

	// Identify user in Heap if there's an Id
	if (response && response.data.contractorId) {
		window.modUtils.heap('identify', [response.data.contractorId, 'ID']);
	}

	if (currentStep === 0) {
		// If first step submission was succesful
		if (response && xhr.status === 201) {
			const $contractorId = window.modForm.opts.form.querySelector('[name="contractorId"]'),
				$token = window.modForm.opts.form.querySelector('[name="token"]');

			$contractorId.value = response.data.contractorId;
			$token.value = response.data.token;

			// Storing token value on a cookie under modernize domain
			window.modForm.opts.sessionObj = {
				token: response.data.token
			};
			window.modUtils.setCookie(
				'mod-session',
				encodeURIComponent(JSON.stringify(window.modForm.opts.sessionObj))
			);

			heapTrackData = {
				message: 'Successful Contractor Signup Form Submit Step 1',
				data: response.data.contractorId
			};
		}
	} else if (currentStep === 1) {
		// If PATCH call submit was successful
		if (xhr.status === 204) {
			heapTrackData = {
				message: 'Successful Contractor Signup Form Submit Step 2',
				data: window.modForm.opts.landingPage
			};
		}

		// Thankyou blocks logic
		const $tyStateBlocks = window.modForm.opts.form.querySelectorAll('.ty-state-block'),
			$generalTyp = window.modForm.opts.form.querySelector('.ty-state-block-success');
		const tyStateGAName = 'thankyou';

		// Switch form to TY state
		window.modForm.opts.form.classList.add(window.modForm.opts.formTyCls);

		// Check what type of TY page to show
		if ($tyStateBlocks && $tyStateBlocks.length) {
			$tyStateBlocks.forEach(function(el) {
				el.style.display = 'none';
			});
		}

		$generalTyp.style.display = 'block';

		// Track TY page in GA
		window.modUtils.gaSend({
			hitType: 'pageview',
			page: window.modForm.opts.landingPage + tyStateGAName + '/'
		}, window.modForm.opts.gaTracker);
	}

	// Track data in Heap
	window.modUtils.heap('track', [heapTrackData.message, heapTrackData.data]);

	showNextStep();
}
