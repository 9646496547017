import { trackFormSubmissionFlow } from '../../helpers';
/**
 * Get Recaptcha token and uppend it to the form data
 * @param {String} token - recaptcha user token
 * @param {Function} cb - callback
 * @param {Boolean} [useFullDomain] - use full domain for request
 */
export function validateRecaptchaResponse(token, cb, useFullDomain = false) {
	trackFormSubmissionFlow({ inValidateRecaptchaResponse: true });
	if (token === '') {
		cb(false, 'No token provided');
		return;
	}

	const data = {
		action: 'validate_recaptcha',
		token: token
	};

	const request = new XMLHttpRequest();
	const url = `${useFullDomain ? `https://${(window.modUtils.getEnv() === 'production' ? '' : 'qa.')}modernize.com` : ''}/wp-admin/admin-ajax.php`;
	trackFormSubmissionFlow({ reCaptchaURL: url });
	request.open('POST', url);
	request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
	request.send(new URLSearchParams(Object.entries(data)).toString());
	request.addEventListener('load', function() {
		if (this.response) {
			if (this.readyState === 4 && this.status === 200) {
				trackFormSubmissionFlow({ reCaptchaSuccess: true });
				const response = JSON.parse(this.response);
				// condition to parse the response again if it is still a type of string
				const jsonResponse = typeof response === 'string' ? JSON.parse(response) : response;
				const isValidToken = jsonResponse.success === true;
				const errorMessage = isValidToken && 'error-codes' in jsonResponse ? jsonResponse.errorCodes.toString() : '';
				cb(isValidToken, errorMessage);
			}
		}
	});
	request.addEventListener('error', function(error) {
		const errorMessage = error.status + ' ' + error.responseText;
		console.log(error);
		trackFormSubmissionFlow({ reCaptchaError: true });
		cb(false, errorMessage);
	});
}
