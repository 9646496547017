
export const initFormSubmitLoaderAssets = function(onloadCallback = () => { }) {
	if ((window.modForm.opts.showLoadingOverlay || window.modForm.opts.showRedirectOverlay) && !window.modForm.opts.isLoaderAssetsLoaded) {
		let mainResourcePath = `${window.modUtils.getModernizeDomain()}/quote/resources/shared-resources/`;

		// Get loader from QA on Local Env
		if (mainResourcePath.indexOf('modernize.local') > -1) {
			mainResourcePath = mainResourcePath.replace('modernize.local', 'qa.modernize.com');
			mainResourcePath = mainResourcePath.replace(/https?:/g, window.location.protocol);
		}

		const loaderJS = document.createElement('script');
		loaderJS.src = `${mainResourcePath}scripts/loader/loader.min.js`;
		loaderJS.defer = true;

		loaderJS.onload = function(e) {
			if (onloadCallback && typeof onloadCallback === 'function') {
				onloadCallback(e);
			}
		};

		const loaderCSS = document.createElement('link');
		loaderCSS.href = `${mainResourcePath}styles/components/loader/loader.min.css`;
		loaderCSS.rel = 'stylesheet';

		document.body.appendChild(loaderJS);
		document.head.appendChild(loaderCSS);
		window.modForm.opts.isLoaderAssetsLoaded = true;
	}
};
