export const opts = {
	vertical: null, // trade of a form; possible values: solar, windows, hvac, roofing, siding
	form: document.getElementById('form'),
	formId: 'form',
	formLoadingCls: 'form--loading',
	formSubmittingCls: 'form--submitting',
	formSubmittedCls: 'form--submit-done',
	formSubmitErrorCls: 'form--submit-error',
	formInProgressCls: 'form-in-progress',
	toggleFormInProgressClass: false, // turn to true if we want formInProgressCls to be added to body when form is moved to second step
	stepsSelector: '[data-bind="step"]', // steps selector
	stepActiveCls: 'step--active', // step active class
	backButtons: document.querySelectorAll('.back-btn, .btn-back, [data-btn="back"]'),
	progressBar: document.querySelectorAll('.progress-bar'),
	modQuoteButtonWrapper: document.querySelectorAll('.form-btns'),
	isFormLoading: false,
	formElemsSelectors: {},
	formElemsSelectorsDefaults: {
		tcpaCopy: '#tcpa-copy', // TCPA visible copy; text version for API will be pulled from it
		maskedPhoneFields: 'input[name="homePhone"]',
		maskedEinFields: 'input[name="ein"]',
		numericsOnlyFields: 'input[name="zip"], input[name="zip1"], input[name="companyZip"]',
		addressField: 'input[name="address"]',
		stateField: 'input[name="state"]',
		cityField: 'input[name="city"]',
		zip1Field: 'input[name="zip1"]',
		zipField: 'input[name="zip"]',
		repairField: 'input[name="repair"]',
		hvacWouldReplaceBlock: '#hvac-would-replace-block', // block that contains wouldReplace control,
		hvacSystemTypeField: 'input[name="hvacSystemType"]',
		hvacFuelTypeBlock: '#hvac-fuel-type-block', // block that contains hvacFuelType control
		utilityProviderWrapper: '#utility-provider-wrapper'
	},
	hvacSystemTypesWithFuel: ['Central Heating', 'Boiler'], // Array of hvac system types (lowercase) that require fuel type selection
	activeInputs: '.step.swiper-slide-active input[type=text], .step.swiper-slide-active input[type=tel], .step.swiper-slide-active input[type=email], .step.swiper-slide-active textarea, .step.swiper-slide-active input[type=radio]',
	fieldErrorCls: 'has-error',
	errorParentCls: 'parent-error',
	gaTracker: 'main', // GA tracker to use
	thankyouPage: '/thankyou/',
	landingPage: window.location.protocol + '//' + window.location.host + window.location.pathname + (window.location.search ? window.location.search : ''),
	hashStepTracking: false,
	useModApi: false,
	useQSApi: false,
	useFormsServiceApi: false,
	userObj: {},
	formSwiperInstance: '',
	prepopulateUserInfo: false,
	useGetMotivationParam: true,
	useAutofill: false,
	useSmoothScrolling: false,
	hasZipChange: false,
	trackZip1ChangeDynamically: false,
	trackZipChangeDynamically: false,
	useTooltips: false,
	showSecureText: false,
	useObserveVWOVariation: false,
	observedTargetNode: document.body, // Select the node that will be observed for mutations
	observerConfig: { attributes: true }, // Options for the observer which mutations to observe
	vwoVariationDataAttribute: 'data-vwo-test',
	observeVWOVariationCallback: function() { },
	vwoVariationDataAttributeValue: '', // Should be the JIRA ticket number of that test
	secureTextStepsSelector: ['.step--personalInfo', '.step--contactInfo'],
	secureTextPosition: 'below-cta', // Options are 'below-cta' & 'above-cta'
	useEncryptedSecureText: false, // Making this true will change the secure text copy to "Encryted form..."
	isLoaderLoaded: false,
	isLoaderAssetsLoaded: false,
	showLoadingOverlay: false,
	showRedirectOverlay: false,
	appendOverlayToElem: document.getElementById('form'),
	ignoreDuplicateSubmissionBlocker: false,
	hvacVerticalName: 'hvac', // To trigger initHvacWouldReplaceBlock function for both Mod & QS
	hvacRepairFieldValue: 'yes',
	addAccessibilityUpdates: false,
	useGlobalInitCarousel: false,
	isOrganicPreferredMatch: false, // use for preferred match
	widgetHashStepTracking: false, // receive messages from child iframes
	abandonmentHtmlPath: 'abandonment/index.html',
	/**
	 * this fires before form is submitted;
	 * @param {Object} formData - form data in key/value form
	 * @returns {Object} - formData to submit to server or false to stop submitting
	 */
	formSubmittingCallback: null,

	/**
	 * this fires when submitting form data to server was successful
	 * @param {Object} response - server response
	 */
	formSubmitSuccessCallback: null,

	/**
	 * this fires when submitting form data to server returned an error
	 * @param {Object} response - server response
	 */
	formSubmitErrorCallback: null,

	/**
	 * this fires before step is changed;
	 * @param {String} oldStepId - current step ID
	 * @param {String} stepId - new step ID
	 * @returns {Mixed} - step ID to switch to or false to stop switching
	 */
	stepChangingCallback: null,

	/**
	 * this fires after step is changed
	 * @param {String} oldStepId - current step ID
	 * @param {String} stepId - new step ID
	 */
	stepChangedCallback: null,

	/**
	 * this fires after zip code is validated on the server
	 * @param {Object} geoData - city and state object
	 */
	zipCodeValidated: null,

	/**
	 * this fires after the zip control field is validated on the server
	 * @param {Object} geoData - city and state object
	 */
	zip1FieldValidated: null,

	/**
	 * this fires after the getConsentListing() function is called
	 */
	getConsentListingCallback: null,

	shouldSuppressConversionPixel: false,
	suppressConversionPixelParam: 'cnvrsnpxl=n',
	useStepNameHash: false,
	delayQuadTransformation: false,
	isFirePixelTrue: false,
	firePixelParam: 'firepxl=y',
	isSharedMatch: false,
	eCommerceVendorKeys: {
		shared: { // these will get a shared= param added to their HRC results page (to add a secondary button for their E-Commerce flow)
			frontdoor: '33097210'
		},
		exclusive: { // these will trigger an exclusive experience if solely matched with one of these vendors
			airo: '31194410',
			angi: '31816410',
			cove: '33788010',
			frontdoor: '33097210',
			networx: '11968410'
		},
		consent: {
			adopt: '31096410'
		}
	},
	eCommerceExclusiveLoaders: {
		frontdoor: [
			`${window.modUtils ? window.modUtils.getModernizeDomain() : 'https://modernize.com'}/quote/resources/assets/images/logos/partners/american-home-shield.svg`,
			'Congrats! You&rsquo;re eligible to see pricing from American Home Shield now.',
			'#004B98',
			'#ED1C24',
			'#004B98',
			'#F9F9F6'
		],
		angi: [
			`${window.modUtils ? window.modUtils.getModernizeDomain() : 'https://modernize.com'}/quote/resources/assets/images/logos/partners/angi.svg`,
			'Transferring to Angi',
			'#A03027',
			'#FF6153',
			'#A03027',
			'#F9F9F6'
		],
		cove: [
			`${window.modUtils ? window.modUtils.getModernizeDomain() : 'https://modernize.com'}/quote/resources/assets/images/logos/partners/cove.svg`,
			'Transferring to Cove',
			'#002334',
			'#3EBEB0',
			'#002334',
			'#F9F9F6'
		],
		networx: [
			`${window.modUtils ? window.modUtils.getModernizeDomain() : 'https://modernize.com'}/quote/resources/assets/images/logos/partners/networx.svg`,
			'Transferring to Networx',
			'#344054',
			'#0088FF',
			'#344054',
			'#F9F9F6'
		]
	},
	sharedParam: 'shared=',
	isExclusiveMatch: false,
	isBrandedExclusiveMatch: false,
	exclusiveMatchResultsPage: '',
	exclusiveMatchVendor: '',
	resultsPageAdditionalParams: {},
	prefVendorKeys: [],
	prefVendorParam: 'prefVendor=',
	landingPageSubdirectory: '',
	isCrossSellForm: false,
	shouldUpdateTCPACompanyNameToModernize: true,
	validateRecaptcha: false,
	recaptchaVersion: 3,
	isVite: false,
	scrollTopOnStepChange: true,
	ignoreQueryParams: [],
	ignoreQueryParamsInSessionStorage: false,
	autoRedirectToTyOrSorryPage: true,
	phoneAssignmentConfig: {
		showPhoneAssignmentNumber: false,
		phoneNumberLinksSelector: '[class*=call-link],[class*=call-button]',
		phoneNumbersTextSelector: '[class*=call-number]',
		phoneNumberContainerSelector: '.call-tracking-controls',
		questQuadTagId: {
			staging: '',
			prod: ''
		},
		matchVendors: [],
		response: null,
		useHsEndpoint: false,
		formatNumberWithBracketsAndDash: false,
		skipMatching: false,
		getPhoneAssignmentDataCallback: null,
		customPhoneNumber: '' // We will show the number passed here in the UI, when user's zip is in call coverage
	},
	fetchAndFormatConsentListings: false, // This Fetches consent listing tcpa vendors, sends the updated tcpa to the lead and formats the list of vendors
	consentCaptureDetails: '',
	ignoreSendingUserTcpaToHeap: false, // This should be true for wholesale implementations
	sendConsentCaptureTcpaToLead: false, // This is purely for tcpa a/b tests where we don't want to fetch consent listings globally but want to send updated tcpa in the lead
	formatConsentCaptureVendors: false, // This is purely for tcpa a/b tests where we don't want to fetch consent listings globally but want to format the list of vendors in the tcpa,
	originalTcpa: '',
	consentRequestId: '',
	consentCaptureVendors: [],
	consentCaptureLabels: {
		wasPreChecked: true,
		wasOfferedSelectAll: false,
		wasPreSubmit: true
	},
	rerunConsentSearchesOnInputChange: false,
	consentCaptureInputChangeSelector: 'input',
	serviceSpecificAttributes: {},
	showBrandedResultPage: false,
	useGlobalSkipSteps: false,
	skipStepVertical: null,
	skipStepsConfig: {},
	disableRecaptcha: false,
	formSubmittingCallbackBeforeFormPost: null
};
