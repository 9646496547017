/**
 * FUTURE DEPRECATE: Fire non-TYP conversion path pixels
 * @param {*} [response] - Used to grab the zip
 * @returns {Boolean} - false if autoRedirectToTyOrSorryPage is also false
 */
export function fireNonTYPConversionPathPixels(response) {
	// https://modernize.atlassian.net/browse/HRC-349
	// temp check for HEX to follow landing page -> hrc funnel
	// will be removed once we're satisfied nonTYP experience isn't harmful to this funnel
	window.modUtils.gaSend(
		{
			hitType: 'event',
			eventCategory: 'HRC General',
			eventAction: 'User converted on non-TYP experience',
			eventLabel: window.location.pathname
		},
		window.modForm.opts.gaTracker
	);

	// Redshift Transforms that normally happen on the TYP
	// window.paramObjs.formpath was used before, leaving this as a note
	const formPath = window.location.hostname + window.location.pathname;

	if (window.heap) {
		window.heap.addEventProperties({
			/* eslint-disable camelcase */
			project_id: window.projectId,
			form_path: formPath
			/* eslint-enable camelcase */
		});
	}

	// Adding VWO data as heap event properties
	if (window._vis_data && Object.keys(window._vis_data).length !== 0) {
		const visData = window._vis_data,
			vwoCampaigns = {};

		Object.keys(visData).forEach(function(testID) {
			vwoCampaigns['VWO Test ID'] = testID.replace(/\D+/g, '');
			vwoCampaigns['VWO Variation Name'] = visData[testID];
		});

		if (window.heap) {
			window.heap.addEventProperties(vwoCampaigns);
		}
	}

	const zip = response && response.zip ? response.zip : undefined;

	// Alternate heap conversion event for Homeowner Lead Form Conversion combo event
	window.modForm.homeownerLeadConversionProps.zip = zip;
	window.modUtils.heap('track', ['Homeowner Lead Conversion', window.modForm.homeownerLeadConversionProps]);

	// track time elapsed between successful form post and HRC redirect
	window.modForm.redirectStart = Date.now();
	// Fire all conversion pixels
	if (typeof dataLayer !== 'undefined') {
		window.dataLayer.push({
			event: 'Homeowner Lead Conversion'
		});
	}

	if (!window.modForm.opts.autoRedirectToTyOrSorryPage) {
		return false;
	}

	// Trying to identify where the ~2% of submitted leads fail at
	window.modUtils.heap('track', [
		'NonTYP Redirect Timer Start', {
			timeElapsed: Date.now()
		}
	]);

	// fallback in case AIO times out
	window.timeOutId = setTimeout(function() {
		const currentClasses = document.querySelector('body').getAttribute('class');
		window.modUtils.heap('track', [
			'Hit Timeout to redirect to HRC',
			{
				classesPresent: currentClasses,
				timeElapsed: Date.now() - window.modForm.redirectStart
			}
		]);

		if (window.Loader && window.modForm.opts.showRedirectOverlay) {
			window.Loader.showRedirectAnimation(); // eslint-disable-line no-undef
			window.modUtils.heap('track', ['Loader Visible - Transferring to Modernize']);
			setTimeout(function() {
				setTimeout(function() {
					window.location.href = window.modForm.projexURL;
				}, 500);
				document.body.classList.add('submitted');
			}, 2500);
		} else {
			document.body.classList.add('submitted');
			setTimeout(function() {
				window.location.href = window.modForm.projexURL;
			}, 500);
		}
	}, 5000);
}
