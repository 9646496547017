
/**
 * Function to track form submission flow in heap.
 *
 * @export
 * @param {object} properties - {}
 */
let currentData = {};
export function trackFormSubmissionFlow(properties = {}) {
	const _fn = function(data = {}) {
		if (Object.keys(data).length) {
			currentData = Object.assign(currentData, data);
			window.Modalytics.heap('track', ['Form Submission Flow', currentData]);
		}
	};

	if (window.modUtils.getEnv() !== 'production') {
		_fn(properties);
	} else {
		if (window.heap) {
			_fn(properties);
		} else {
			document.addEventListener('heap.', function() {
				_fn(properties);
			});
		}
	}
}
