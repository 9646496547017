import { trackFormSubmissionFlow } from '../../helpers';
/**
 * Switch form loading state
 * @param {Bool} isLoading - true to switch on loading state, false to switch if off
 */
export function switchFormLoadingState(isLoading) {
	trackFormSubmissionFlow({ inSwitchFormLoadingState: true });
	if (isLoading) {
		window.modForm.opts.form.classList.add(window.modForm.opts.formLoadingCls);
		window.modForm.opts.isFormLoading = true;
	} else {
		window.modForm.opts.form.classList.remove(window.modForm.opts.formLoadingCls);
		window.modForm.opts.isFormLoading = false;
	}
}
