/* globals Loader */
import { parseHTMLFromString, trackFormSubmissionFlow } from '../../../helpers';
/**
 * Adding new template for loader
 */
export function initFormSubmitLoader() {
	if (window.Loader && (window.modForm.opts.showLoadingOverlay || window.modForm.opts.showRedirectOverlay) && !window.modForm.opts.isLoaderLoaded) {
		trackFormSubmissionFlow({ initiatingLoader: true });
		const loaderMarkUp = parseHTMLFromString(Loader.getLoaderHTML()); // eslint-disable-line no-undef
		window.modForm.opts.appendOverlayToElem.append(loaderMarkUp);
		window.modForm.opts.isLoaderLoaded = true;
	}
}
