import { switchFormLoadingState, getProjExUrl, createHeapSessionIdCookie, heapIdentify } from '../../helpers';
import { isQsSiteEligibleForHrc } from '../../validators';
/**
 * Process form after succesful submit
 * @param {Object} response - response data
 * @param {Object} xhr - xhr
 * @returns {Boolean} Submission status
 */
export function processQSFormSubmitSuccess(response, xhr) {
	let cookieData;
	const responseData = response.RESPONSE,
		dataCaptureKey = responseData.DCK,
		zip = responseData.zip;

	// If submission was succesful
	if (dataCaptureKey !== null && xhr.status === 200) {
		const clickKeyUUID = window.modForm.opts.formData.CLK;

		// Setting GA User ID and several custom dimensions after a successful form submit request
		if (typeof dataLayer !== 'undefined') {
			window.dataLayer.push({
				event: 'formSubmit',
				userId: clickKeyUUID,
				lastProjectId: dataCaptureKey,
				projectId: dataCaptureKey,
				projectClass: window.modForm.opts.formData.service
			});
		}

		// Sending GA Event to keep track of all non-TYP conversions, including this one
		window.modUtils.gaSend({
			hitType: 'event',
			eventCategory: 'HRC General',
			eventAction: 'User converted on non-TYP experience',
			eventLabel: window.location.pathname
		}, window.modForm.opts.gaTracker);

		// One last attempt at identifying the user before redirecting
		heapIdentify();

		// Alternate heap conversion event for Homeowner Lead Form Conversion combo event
		window.modForm.homeownerLeadConversionProps.zip = zip;
		window.modUtils.heap('track', ['Homeowner Lead Conversion', window.modForm.homeownerLeadConversionProps]);

		// VWO custom JS goal code
		if (window.modForm.opts.GOAL_ID) {
			window._vis_opt_queue = window._vis_opt_queue || []; // eslint-disable-line camelcase
			window._vis_opt_queue.push(function() {
				_vis_opt_goal_conversion(window.modForm.opts.GOAL_ID); // eslint-disable-line camelcase, no-undef
			});
		}

		// Fire all conversion pixels
		if (typeof window.dataLayer !== 'undefined') {
			window.dataLayer.push({
				event: 'Homeowner Lead Conversion',
				isQSPage: 'true'
			});
		}

		// Track checkout in Pinterest
		if ('undefined' !== typeof pintrk) {
			pintrk('track', 'checkout', { // eslint-disable-line no-undef
				order_id: 'undefined' !== typeof dataCaptureKey ? dataCaptureKey : null // eslint-disable-line camelcase
			});
		}

		// Save response and quadlink params to cookie and redirect to the corresponding thankyou page
		cookieData = Object.assign({}, window.modForm.opts.quadLinkParams, responseData);

		// Set personal information
		cookieData.PROJECT = {};

		if (!isQsSiteEligibleForHrc()) {
			cookieData.PROJECT.FIRSTNAME = window.modForm.opts.formData.FN;
		}

		// Append trade/vertical in cookie to get the reference in result page
		let contractorTrade = window.modForm.opts.contractorTrade ? window.modForm.opts.contractorTrade : window.modForm.opts.vertical;
		contractorTrade = contractorTrade.replace(/_/g, ' ').toLowerCase();
		cookieData.PROJECT.contractorTrade = contractorTrade;

		window.modUtils.setCookie('qsLeadData', JSON.stringify(cookieData));

		if ('function' === typeof window.modForm.opts.formSubmitSuccessCallback) {
			if (window.modForm.opts.formSubmitSuccessCallback(responseData) === false) {
				return false;
			}
		}
		// Handling errors, conditional is when no DCK is found
	} else {
		switchFormLoadingState(false);

		if ('function' === typeof window.modForm.opts.formSubmitErrorCallback) {
			if (window.modForm.opts.formSubmitErrorCallback(responseData) === false) {
				return false;
			}
		}
	}

	// Set results page to HRC if the site is eligible, there's a DCK
	// and the current page is not a quote emerging trade page (these should not redirect to the HRC)
	if (isQsSiteEligibleForHrc() && responseData.HASHEDDCK && !window.modForm.opts.showBrandedResultPage && !window.modForm.opts.isBrandedExclusiveMatch) {
		window.projectId = responseData.HASHEDDCK;
		window.modForm.opts.resultsPage = getProjExUrl();
		window.modForm.opts.resultsPage += (window.modForm.opts.resultsPage.indexOf('?') < 0 ? '?' : '&') + 'DCK=' + dataCaptureKey;

		if (window.modForm.opts.shouldSuppressConversionPixel) {
			window.modForm.opts.resultsPage += (window.modForm.opts.resultsPage.indexOf('?') < 0 ? '?' : '&') + window.modForm.opts.suppressConversionPixelParam;
		}

		if (window.modForm.opts.isFirePixelTrue) {
			window.modForm.opts.resultsPage += (window.modForm.opts.resultsPage?.indexOf('?') < 0 ? '?' : '&') + window.modForm.opts.firePixelParam;
		}

		if (window.modForm.opts.isSharedMatch) {
			window.modForm.opts.resultsPage += (window.modForm.opts.resultsPage.indexOf('?') < 0 ? '?' : '&') + window.modForm.opts.sharedParam;
		}

		if (Object.keys(window.modForm.opts.resultsPageAdditionalParams).length) {
			Object.entries(window.modForm.opts.resultsPageAdditionalParams).forEach(function([key, value]) {
				window.modForm.opts.resultsPage += (window.modForm.opts.resultsPage.indexOf('?') < 0 ? '?' : '&') + key + '=' + value;
			});
		}

		if (window.modForm.opts.prefVendorParam && (window.modForm.opts.prefVendorKeys && window.modForm.opts.prefVendorKeys.length)) {
			window.modForm.opts.prefVendorParam += window.modForm.opts.prefVendorKeys.toString();
			window.modForm.opts.resultsPage += (window.modForm.opts.resultsPage.indexOf('?') < 0 ? '?' : '&') + window.modForm.opts.prefVendorParam;
		}

		if (window.modForm.opts && window.modForm.opts.getVars && 'PartnerSourceID' in window.modForm.opts.getVars) {
			window.modForm.opts.resultsPage += (window.modForm.opts.resultsPage.indexOf('?') < 0 ? '?' : '&') + `PartnerSourceID=${window.modForm.opts.getVars.PartnerSourceID}`;
		}

		// Append TY to HRC URL for QS tracking purpose
		window.modForm.opts.resultsPage += (window.modForm.opts.resultsPage.indexOf('?') < 0 ? '?' : '&') + (responseData.MATCHES.length > 0 ? 'ThankYou' : 'Sorry');
	} else {
		// Moving this logic to centralized file where we change the /ThankYou path for /Sorry in case there were no matches
		window.modForm.opts.resultsPage = responseData.MATCHES.length === 0 ? window.modForm.opts.errorRedirectPath : window.modForm.opts.resultsPage;
	}

	// Append TY to HRC URL for QS tracking purpose
	if (
		typeof window.modForm.opts.appendTyToHrcUrl !== 'undefined'
		&& window.modForm.opts.appendTyToHrcUrl === true
		&& window.modForm.opts.resultsPage.indexOf('ThankYou') < 0
		&& window.modForm.opts.resultsPage.indexOf('Sorry') < 0
	) {
		window.modForm.opts.resultsPage += (window.modForm.opts.resultsPage.indexOf('?') < 0 ? '?' : '&') + (responseData.MATCHES.length > 0 ? 'ThankYou' : 'Sorry');
	}

	if (window.modForm.opts.isExclusiveMatch && window.modForm.opts.exclusiveMatchResultsPage) {
		window.modForm.opts.resultsPage = window.modForm.opts.exclusiveMatchResultsPage;
	}

	// Adding new HRC URL to Loader link
	if (window.Loader && window.modForm.opts.showLoadingOverlay) {
		window.Loader.updateLink(window.modForm.opts.resultsPage); // eslint-disable-line no-undef
	}

	// Renamed thankyou page to results page to be more explicit that TYP is dying
	if (window.modForm.opts.resultsPage && !window.modForm.opts.isOrganicPreferredMatch && window.modForm.opts.autoRedirectToTyOrSorryPage) {
		if (window.modForm.opts.showRedirectOverlay) {
			if (window.modForm.opts.isExclusiveMatch) {
				const capitalizedMatchVendor = window.modForm.opts.exclusiveMatchVendor.charAt(0).toUpperCase() + window.modForm.opts.exclusiveMatchVendor.slice(1),
					exclusiveMatchHeapMessage = capitalizedMatchVendor === 'Frontdoor' ? 'American Home Shield' : capitalizedMatchVendor;

				if (window.Loader) {
					window.Loader.showRedirectAnimation(...window.modForm.opts.eCommerceExclusiveLoaders[window.modForm.opts.exclusiveMatchVendor]); // eslint-disable-line no-undef
					window.Modalytics.heap('track', [`Loader Visible - Transferring to ${exclusiveMatchHeapMessage}`]);
				}
			} else if (window.Loader) {
				window.Loader.showRedirectAnimation(); // eslint-disable-line no-undef
				window.Modalytics.heap('track', ['Loader Visible - Transferring to Modernize']);
			}
			setTimeout(function() {
				setTimeout(function() {
					if (window.modForm.opts.isExclusiveMatch && window.modForm.opts.exclusiveMatchResultsPage && window.modForm.opts.isLeadWidget && window.parent && window.parent !== window) {
						window.parent.postMessage({ exclusiveRedirectUrl: window.modForm.opts.exclusiveMatchResultsPage }, '*');
						return;
					} else {
						window.location.href = window.modForm.opts.resultsPage;
					}
				}, 500);
				document.body.classList.add('submitted');
			}, 2500);
		} else if (window.modForm.opts.showLoadingOverlay) {
			document.body.classList.add('submitted');
			setTimeout(function() {
				window.location.href = window.modForm.opts.resultsPage;
			}, 1000);
		} else {
			window.location.href = window.modForm.opts.resultsPage;
		}
	}

	if (window.modForm.opts.isFrontdoorExclusiveMatch) {
		createHeapSessionIdCookie(encodeURIComponent(window.modForm.opts.resultsPage));
	} else {
		createHeapSessionIdCookie(window.modForm.opts.resultsPage);
	}
}
