/* eslint-disable */

/**
 * Function to get XMLHttpRequest request state.
 *
 * @export
 * @param {*} [xhr=null] - XMLHttpRequest object
 * @link https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/readyState
 * @returns {string} - XMLHttpRequest request state
 */
export function getRequestState(xhr = null) {
	if (!xhr || !xhr.readyState) {
		return null;
	}

	switch (xhr.readyState) {
		case 0:
			return `${xhr.readyState} | UNSENT`;
		case 1:
			return `${xhr.readyState} | OPENED`;
		case 2:
			return `${xhr.readyState} | HEADERS_RECEIVED`;
		case 3:
			return `${xhr.readyState} | LOADING`;
		case 4:
			return `${xhr.readyState} | DONE`;
		default:
			return null;
	}
}
